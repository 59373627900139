/**
 * Created by Amine on 27/07/2016. edited 20/02/2020
 */
(function () {

    'use strict';

    const getObjectId = require('shared/utils/get-object-id');

    class PaymentService {
        constructor($http, mnWebSocket, $q, $mdDialog, $state, $translate, stockService, moment) {
            this.http = $http;
            this.ws = mnWebSocket;
            this.q = $q;
            this.dialog = $mdDialog;
            this.state = $state;
            this.translate = $translate;
            this.stockService = stockService;
            this.moment = moment;

            this.exemptVisit = this._exemptingVisit(true);
            this.unexemptVisit = this._exemptingVisit(false);
            // this.exemptDentalTreatmentPlan = this._exemptingTreatmentPlan(true);
            // this.unexemptDentalTreatmentPlan = this._exemptingTreatmentPlan(false);

            this.applyDiscount = (type, visit, payment) => this._applyDiscount(type, visit, payment);
            this.validateDeliveryDocument = this._deliveryDocumentValidation();
            this.invalidateDeliveryDocument = this._deliveryDocumentValidation(false);
        }

        static get $inject() {
            return ["$http", "mnWebSocket", "$q", "$mdDialog", "$state", "$translate", "stockService", "moment"];
        }

        getCls(key) {
            const _cls = {
                visit: "VisitPaymentDetail",
                plan: "TreatmentPlanPaymentDetail",
                medical_plan: "MedicalPlanPaymentDetail",
                patient: {
                    _module: "patient.models",
                    _model: "Patient"
                },
                organization: {
                    _module: "shared.insurance.models",
                    _model: "Organization"
                },
                unregistered: {
                    _module: "payment.models",
                    _model: "UnregisteredPayer"
                }
            };

            return _.get(_cls, key, "PaymentDetail");
        }

        addPayment(payment) {
            let deferred = this.q.defer();
            const url = "/api/payment/";

            this.http.post(url, payment)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        saveEncasement(encasement) {
            let deferred = this.q.defer();
            const url = `/api/encasement-form/${encasement.id ? `${encasement.id}/` : ''}`;

            this.http[encasement.id ? "put" : "post"](url, encasement)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        getEncasement(id) {
            let deferred = this.q.defer();
            const url = `/api/encasement/${id}/`;

            this.http.get(url, {id: id})
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        visitPayments(id) {
            return this.ws.call("payment.Payment.visit_payments", _.isObject(id) ? _.pick(id, ['id']) : {id})
        }

        resetVisitPayments(id) {
            return this.ws.call("visit.VisitPayment.reset_payments", _.isObject(id) ? _.pick(id, ['id']) : {id});
        }

        resetEncasement(id) {
            return this.ws.call("payment.Encasement.reset", _.isObject(id) ? _.pick(id, ['id']) : {id});
        }

        deleteEncasement(encasement, ev, showMessage = true) {
            const deferred = this.q.defer();
            const url = `/api/encasement/${encasement.id}/`;

            const remove = () => {
                this.resetEncasement(encasement.id)
                    .then(() => {
                        this.http.delete(url, {id: encasement.id})
                            .then(response => deferred.resolve(response.data), deferred.reject);
                    });
            };

            if (showMessage) {
                const confirm = this.dialog.confirm()
                    .title(this.translate.instant("encasement_remove_confirm_title"))
                    .textContent(this.translate.instant("encasement_remove_confirm_text"))
                    .ariaLabel('remove plan confirm')
                    .targetEvent(ev)
                    .ok(this.translate.instant('confirm_ok'))
                    .cancel(this.translate.instant('confirm_cancel'));

                this.dialog.show(confirm).then(() => remove(), deferred.reject);

                return deferred.promise;
            }
        }

        refreshEntry(entryId) {
            return () => {
                if (!_.isNil(entryId)) {
                    this.ws.pub("frontdesk.Practice.entry_updated", {
                        reload: true,
                        id: entryId
                    }, false);
                }
            }
        }

        prePaymentValidation(entry, encasement) {
            const total = encasement.total_amount;
            const visit = {
                id: entry.visit_id,
                financial_status: _.assign(entry.visit_financial_status, {
                    paid_amount: total,
                    is_validated: true,
                    validate_at: this.moment()
                })
            }

            const payment = {
                total_amount: total,
                payment_date: encasement.encasement_date,
                transaction_uid: getObjectId(),
                details: [{
                    total_amount: total,
                    visit: visit,
                    _cls: this.getCls('visit')
                }],
                encasement: _.pick(encasement, 'id')
            };

            return this.addPayment(payment)
        }

        payEntry($event, entry) {
            const success = () => {
                this.payVisit($event, entry.visit_id, entry)
                    .then(this.refreshEntry(entry.id), _.noop);
            };

            if (entry.has_encasement) {
                this.getEncasement(entry.encasement)
                    .then(encasement => {
                        if (encasement.total_amount === entry.visit_financial_status.total) this.prePaymentValidation(entry, encasement)
                            .then(() => success())
                        else success();
                    });
            } else success();
        }

        payVisit($event, visit, entry) {
            const dialog = require("payment/dialogs/entry-payment-dialog");

            return this.dialog.show(_.assign({}, dialog, {
                targetEvent: $event,
                locals: {visit, entry}
            }), _.noop);
        }

        prePayEntry($event, entry) {
            const dialog = require("payment/dialogs/entry-pre-payment-dialog");

            return this.dialog.show(_.assign({}, dialog, {
                targetEvent: $event,
                locals: {entry}
            }))
        }

        closeVisit($event, visit) {
            const deferred = this.q.defer();
            const confirm = this.dialog.confirm()
                .title(this.translate.instant('visit_closing_confirm'))
                .ariaLabel('close visit confirm')
                .targetEvent($event)
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'));

            this.dialog.show(confirm).then(() => {
                this.ws.call("visit.VisitPayment.close", _.pick(visit, 'id'))
                    .then(deferred.resolve, deferred.reject);
            });

            return deferred.promise;
        }

        reopenVisit($event, visit) {
            const deferred = this.q.defer();
            const confirm = this.dialog.confirm()
                .title(this.translate.instant('visit_reopen_confirm'))
                .ariaLabel('reopen visit confirm')
                .targetEvent($event)
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'));

            this.dialog.show(confirm).then(() => {
                this.ws.call("visit.VisitPayment.reopen", _.pick(visit, 'id'))
                    .then(deferred.resolve, deferred.reject);
            });

            return deferred.promise;
        }

        _exemptingVisit(exempt) {
            return (visit, date) => {
                let data = {
                    is_exempt: exempt,
                    exempt_at: exempt ? date : null
                };

                return this.togglingVisitStatus(visit, data);
            }
        }

        togglingVisitStatus(visit, exempting_data) {
            const deferred = this.q.defer();
            const url = `/api/visit/${visit.id}/`;

            this.http.put(url, {financial_status: exempting_data})
                .then(response => deferred.resolve(response.data), deferred.promise);

            return deferred.promise;
        }

        dentalTreatmentPlanPayments(id) {
            return this.ws.call("payment.Payment.dental_treatment_plan_payments", _.isObject(id) ? _.pick(id, ['id']) : {id})
        }

        resetDentalTreatmentPlanPayments(id) {
            return this.ws.call("dental_consultation.TreatmentPlanPayment.reset_payments", _.isObject(id) ? _.pick(id, ['id']) : {id});
        }

        payDentalTreatmentPlan($event, patientId, planId = null) {
            const dialog = require("payment/dialogs/treatment-plan-payment-dialog");

            return this.dialog.show(_.assign({}, dialog, {
                targetEvent: $event,
                locals: {patientId, planId}
            }));
        }

        closeDentalTreatmentPlan($event, plan, force = false) {
            const deferred = this.q.defer();
            const confirm = this.dialog.confirm()
                .title(this.translate.instant('dental_plan_closing_confirm'))
                .ariaLabel('close plan confirm')
                .targetEvent($event)
                .multiple(true)
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'));

            const success = () => {
                this.ws.call("dental_consultation.TreatmentPlanPayment.close", _.pick(plan, 'id'))
                    .then(deferred.resolve, deferred.reject);
            }

            if (!force) this.dialog.show(confirm).then(() => success(), _.noop);
            else success();

            return deferred.promise;
        }

        reopenDentalTreatmentPlan($event, plan, force = false) {
            const deferred = this.q.defer();
            const confirm = this.dialog.confirm()
                .title(this.translate.instant('dental_plan_reopen_confirm'))
                .ariaLabel('reopen plan confirm')
                .targetEvent($event)
                .multiple(true)
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'));

            const success = () => {
                this.ws.call("dental_consultation.TreatmentPlanPayment.reopen", _.pick(plan, 'id'))
                    .then(deferred.resolve, deferred.reject);
            }

            if (!force) this.dialog.show(confirm).then(() => success(), _.noop);
            else success();

            return deferred.promise;
        }

        medicalPlanPayments(id) {
            return this.ws.call("payment.Payment.medical_plan_payments", _.isObject(id) ? _.pick(id, ['id']) : {id});
        }

        resetMedicalPlanPayments(id) {
            return this.ws.call("medical_care.MedicalPlanPayment.reset_payments", _.isObject(id) ? _.pick(id, ['id']) : {id});
        }

        payMedicalPlan($event, patientId, planId = null) {
            const dialog = require("payment/dialogs/medical-plan-payment-dialog");

            return this.dialog.show(_.assign({}, dialog, {
                targetEvent: $event,
                locals: {patientId, planId}
            }));
        }

        closeMedicalPlan($event, plan, force = false) {
            const deferred = this.q.defer();
            const confirm = this.dialog.confirm()
                .title(this.translate.instant('medical_care.closing_confirm'))
                .ariaLabel('close plan confirm')
                .targetEvent($event)
                .multiple(true)
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'));

            const success = () => {
                this.ws.call("medical_care.MedicalPlanPayment.close", _.pick(plan, 'id'))
                    .then(deferred.resolve, deferred.reject);
            };

            if (!force) this.dialog.show(confirm).then(() => success(), _.noop);
            else success();

            return deferred.promise;
        }

        reopenMedicalPlan($event, plan, force = false) {
            const deferred = this.q.defer();
            const confirm = this.dialog.confirm()
                .title(this.translate.instant('medical_care.reopen_confirm'))
                .ariaLabel('reopen plan confirm')
                .targetEvent($event)
                .multiple(true)
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'));

            const success = () => {
                this.ws.call("medical_care.MedicalPlanPayment.reopen", _.pick(plan, 'id'))
                    .then(deferred.resolve, deferred.reject);
            }

            if (!force) this.dialog.show(confirm).then(() => success(), _.noop);
            else success();

            return deferred.promise;
        }

        // _exemptingTreatmentPlan(exempt) {
        //     return (plan, date) => {
        //         let data = {
        //             is_exempt: exempt,
        //             exempt_at: exempt ? date : null
        //         };
        //
        //         return this.togglingDentalTreatmentPlanStatus(plan, data);
        //     }
        // }
        //
        // togglingDentalTreatmentPlanStatus(visit, exempting_data) {
        //     let deferred = this.q.defer();
        //     const url = `/api/dental-treatment-plan/${visit.id}/`;
        //
        //     this.http.put(url, {financial_status: exempting_data})
        //         .then(response => deferred.resolve(response.data), deferred.reject);
        //
        //     return deferred.promise;
        // }

        applyPercentage(fieldType, total, value, percentage) {
            if (total > 0) {
                if (_.eq(fieldType, "value")) {
                    const attrValue = _.round(_.get(value.object, value.attr, 0) / total, 6)
                    _.set(percentage.object, percentage.attr, attrValue);
                } else {
                    const attrValue = total * _.round(_.get(percentage.object, percentage.attr, 0), 6)
                    _.set(value.object, value.attr, attrValue);
                }
            } else {
                _.set(percentage.object, percentage.attr, 0);
                _.set(value.object, value.attr, 0);
            }
        }

        _applyDiscount(type, payable, payment) {
            let grossAmount = payable.financial_status['gross_total'];

            this.applyPercentage(type, grossAmount,
                {
                    object: payable.financial_status,
                    attr: "global_discount"
                },
                {
                    object: payable.financial_status,
                    attr: "global_percentage_discount"
                }
            );

            payable.financial_status.total = grossAmount - payable.financial_status.global_discount;

            if (!_.isNil(payment) && payment.amount > payable.financial_status.total) {
                payment.amount = payable.financial_status.total;
            }
        }

        applyAffectation(fieldType, payable, affectation, amount = null) {
            this.applyPercentage(fieldType, amount ? amount : payable.financial_status.total, {
                object: affectation,
                attr: "affected_amount"
            }, {
                object: affectation,
                attr: "affected_percentage"
            })
        }

        getVisits(query) {
            return this.ws.call("payment.FinancialStatementVisit.list", query);
        }

        getDentalTreatmentPlan(query) {
            return this.ws.call("payment.FinancialStatementTreatmentPlan.list", query);
        }

        getDentalConsultation(query) {
            return this.ws.call("payment.FinancialStatementDentalConsult.list", query);
        }

        getMedicalPlan(query) {
            return this.ws.call("payment.FinancialStatementMedicalPlan.list", query);
        }

        getOrganizationVisits(query) {
            return this.ws.call("payment.FinancialStatementVisit.organization_list", query);
        }

        getOrganizationTreatmentPlan(query) {
            return this.ws.call("payment.FinancialStatementTreatmentPlan.organization_list", query);
        }

        getEncasements(query) {
            return this.ws.call("payment.FinancialStatementEncasement.list", query);
        }

        getEncasementsSummary(query) {
            return this.ws.call("payment.FinancialStatementEncasement.summary", query);
        }

        encasementState(encasement, reset) {
            const id = _.isUndefined(encasement) ? null : encasement.id;

            return this.state.go("app.payment.form", {
                encasementId: id,
                reset: _.isNil(reset) ? null : reset
            }, {inherit: true});
        }

        getGeneralAccount(query) {
            return this.ws.call("payment.FinancialStatementGeneralAccount.list", query);
        }

        getAccountDetails(pk, type = "P") {
            return this.ws.call("payment.FinancialStatementGeneralAccount.account_details", type === "P" ? {patient: pk} : {organization: pk});
        }

        mainState() {
            this.state.go("app.payment.main", {}, {inherit: true});
        }

        validateDeliveryDocuments(paymentId) {
            return this.ws.call("payment.Payment.validate_delivery_documents", {id: paymentId});
        }

        _deliveryDocumentValidation(validate = true) {
            return (document) => {
                if (validate) return this.stockService.validateMovement(_.get(document, 'id'), "DeliveryForm", "OUT");
                else return this.stockService.invalidateMovement(_.get(document, 'id'), "DeliveryForm", "OUT");
            }
        }

    }

    module.exports = PaymentService;
})();
